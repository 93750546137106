<template>
  <div class="authorization-list">
    <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
    <van-row class="authorization-list-wrap" v-if="dataList.length >0" gutter="18">
      <van-col v-for="item in dataList" span="24" :key="item.id">
        <div class="authorization-item">
          <van-image class="app-icon" :src="item.imgUrl" />
          <p class="app-name">{{item.name}}</p>
          <van-icon class="delete-icon" name="close" @click="deleteAuth(item.id)"/>
        </div>
      </van-col>
    </van-row>
    <div v-else>
      <van-empty :description="$t('common.noData')" />
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import i18n from '@/assets/lang/index';
import { queryAuthorizationManage, authorizationRevocation } from "@/api";

export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
   
    return {
      goBack,
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      dataList: [],
    };
  },
  
  mounted() {
    this.fetchData();
  },
  
  methods: {
    fetchData() {
      queryAuthorizationManage().then(res => {
        this.dataList = res.data || [];
      });
    },
    deleteAuth(id) {
      this.$myDialog.confirm({
        title: i18n.global.t('alert.warnning'),
        message: i18n.global.t('alert.deleteAuth'),
      })
      .then(() => {// on confirm
        authorizationRevocation({ id }).then(res => {
          this.fetchData();
        });
      })
      .catch(() => {});
    },
  },
};
</script>

<style lang="less">
.authorization-list {
  .authorization-list-wrap{
    margin: 20px;
    padding: 4px 0;
    border-radius: 8px;
    background: #fff;
    .authorization-item {
      display: flex;
      align-items: center;
      line-height: 24px;
      margin: 0 15px;
      padding: 12px 0;
      font-size: 14px;
      background-color: #fff;
      border-bottom: 1px solid #efefef;
    }
    .van-col:last-child .authorization-item {
      border-bottom: none; 
    }
    .app-icon,
    .app-icon img{
      width: 35px;
      height: 35px;
    }
    .app-name {
      margin-left: 14px;
      font-weight: 700;
      flex: 1;
      color:#3d3d3d;
    }
    .delete-icon {
      display: block;
      margin-left: 6px;
      font-size: 24px;
      color: red;
    }
  }
}
</style>
